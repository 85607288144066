import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "filename", "input" ]
  
  setFilenameFromFile(file) {
    this.filenameTarget.textContent = file.name
  }
  
  update() {
    const files = this.inputTarget.files
    
    if (files.length > 0) {
      this.setFilenameFromFile(files[0])
    }
  }
}